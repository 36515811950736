import React, { useEffect } from 'react';
import { Menu } from 'antd';
import { AppstoreOutlined, BarChartOutlined, CloudOutlined, ShopOutlined, TeamOutlined, UserOutlined, UploadOutlined, VideoCameraOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import { useLocation, useNavigate } from 'react-router-dom';

export default function AppSiderMenu() {
  const location = useLocation();
  const items = [
    {
      label: '데시보드',
      key: 'menu-1',
      uri: '',
      icon: React.createElement(UserOutlined),
    },
    {
      label: '회원관리',
      key: 'menu-2',
      uri: 'members',
      icon: React.createElement(UserOutlined),
    },
    {
      label: '브랜드관리',
      key: 'menu-3',
      uri: 'brands',
      icon: React.createElement(UserOutlined),
    },
    {
      label: '스토어관리',
      key: 'menu-4',
      uri: 'stores',
      icon: React.createElement(UserOutlined),
    },
    // {
    //   label: "설정",
    //   key: "menu-5",
    //   uri: "settings",
    //   icon: React.createElement(UserOutlined),
    // },
  ];
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = React.useState<string>('');
  const handleClickMenu = ({ key }: MenuInfo) => {
    if (selectedKey !== key) {
      const index = items.findIndex((value: any) => value.key === key);
      navigate(items[index].uri);
    }
  };

  useEffect(() => {
    const pathname = location.pathname;
    const index = items.findIndex((value: any) => value.uri === pathname.replace('/', ''));
    setSelectedKey(items[index].key);
  }, [location]);

  if (selectedKey === null) {
    return null;
  }

  console.log(selectedKey);
  return <Menu theme="light" mode="inline" selectedKeys={[selectedKey]} items={items} onClick={handleClickMenu} style={{ height: 'calc(100vh - 64px)' }} />;
}
