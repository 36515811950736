import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { readAllBrandCategory } from '../../../../api';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

interface IProps {
  form: FormInstance;
  dataSource: any;
}

export default function BrandForm(props: IProps) {
  const { form, dataSource } = props;
  const [brandImage, setBrandImage] = React.useState<any>(null);
  const [logo, setLogo] = React.useState<any>(null);
  const [loading] = React.useState(false);
  const [isFormReady, formReady] = React.useState(false);
  const [fields, setFields] = React.useState<any>({
    category: [],
  });

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 100);
  };
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  const handleChangeBrandImage: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setBrandImage(url);
      });
    }
  };

  const handleChangeLogo: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    console.log(info.file);
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setLogo(url);
      });
    }
  };

  useEffect(() => {
    const bootstrap = async () => {
      if (dataSource.id > 0) {
        form.setFieldsValue({
          name: dataSource.name,
          address: dataSource.address,
          detailTitle: dataSource.detailTitle,
          detailDetail: dataSource.detailContent,
        });
      }

      if (dataSource.id > 0) {
        setLogo(dataSource.logo);
        setBrandImage(dataSource.brandImage);
      } else {
        setLogo('');
        setBrandImage('');
      }
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const category = await readAllBrandCategory();
        setFields({ category });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        name: '',
        address: '',
        detailTitle: '',
        detailDetail: '',
      }}
    >
      <Form.Item name="name" label="브랜드명" rules={[{ required: true, message: '브랜드명을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="address" label="주소" rules={[{ required: true, message: '주소를 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="detailTitle" label="브랜드 상세 제목" rules={[{ required: true, message: '브랜드 상세 제목을 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="detailDetail" label="브랜드 상세 설명" rules={[{ required: true, message: '브랜드 상세 설명을 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="brandImage"
        label="브랜드 상세 이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '브랜드 상세 이미지를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="brandImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeBrandImage}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {brandImage ? <img src={brandImage} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="logo"
        label="이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '이미지를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="logo"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeLogo}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {logo ? <img src={logo} alt="avatar" style={{ width: '100%', maxHeight: 104 }} /> : uploadButton}
        </Upload>
      </Form.Item>
    </Form>
  );
}
