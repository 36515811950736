import * as React from 'react';
import { Table, Tag } from 'antd';
import { getTableColumnNo } from '../../../helpers/table';
import moment from 'moment';
import DrawerBookmarkListByMember from './DrawerBookmarkListByMember';
import DrawerFollowListByMember from './DrawerFollowListByMember';

export default function ListTable(props: any) {
  const { loading, dataSource, pagination } = props;
  const [bookmarkDrawer, setBookmarkDrawer] = React.useState<any>({ visible: false, data: null });
  const [followDrawer, setFollowDrawer] = React.useState<any>({ visible: false, data: null });
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, pagination.pageSize, pagination.current),
          {
            title: '가입일자',
            dataIndex: 'createdAt',
            render(value) {
              return moment(value).format('YYYY-MM-DD HH:mm');
            },
          },
          {
            title: '탈퇴일자',
            dataIndex: 'withdrawAt',
            render(value) {
              if (value) {
                return moment(value).format('YYYY-MM-DD HH:mm');
              } else {
                return '';
              }
            },
          },
          {
            title: '이름',
            dataIndex: 'name',
          },
          {
            title: '닉네임',
            dataIndex: 'nickname',
          },
          {
            title: '이메일',
            dataIndex: 'email',
          },
          {
            title: '마케팅동의',
            dataIndex: 'isTermMarketing',
            render(value) {
              if (value === 'Y') {
                return <Tag color="success">동의</Tag>;
              } else {
                return <Tag color="red">비동의</Tag>;
              }
            },
          },
          {
            title: '앱푸쉬',
            dataIndex: 'isAppPush',
            render(value) {
              if (value === 'Y') {
                return <Tag color="success">동의</Tag>;
              } else {
                return <Tag color="red">비동의</Tag>;
              }
            },
          },
          {
            title: '팔로우수',
            dataIndex: 'followCount',
            render(value, record: any) {
              if (value < 1) {
                return 0;
              }
              return (
                <a
                  onClick={() => {
                    setFollowDrawer({ visible: true, data: record.id });
                  }}
                >
                  {value}
                </a>
              );
            },
          },
          {
            title: '북마크수',
            dataIndex: 'bokkmarkCount',
            render(value, record: any) {
              if (value < 1) {
                return 0;
              }
              return (
                <a
                  onClick={() => {
                    setBookmarkDrawer({ visible: true, data: record.id });
                  }}
                >
                  {value}
                </a>
              );
            },
          },
        ]}
      />
      <DrawerBookmarkListByMember {...bookmarkDrawer} onRequestClose={() => setBookmarkDrawer({ visible: false, data: null })} />
      <DrawerFollowListByMember {...followDrawer} onRequestClose={() => setFollowDrawer({ visible: false, data: null })} />
    </>
  );
}
