import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select, Upload, message, DatePicker } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { readAllBrandCategory } from '../../../../api';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import moment from 'moment';
import locale from 'antd/es/date-picker/locale/ko_KR';
const { Option } = Select;

const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};
const beforeUpload = (file: RcFile) => {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
};

interface IProps {
  form: FormInstance;
  dataSource: any;
  onRequestBrandSelect: () => void;
  onFinish: (removeImages: any) => void;
}

export default function StoreForm(props: IProps) {
  const { form, dataSource, onRequestBrandSelect, onFinish } = props;
  const [logoUrl, setLogoUrl] = React.useState<any>(null);
  const [headerLogoUrl, setHeaderLogoUrl] = React.useState<any>(null);
  const [images, setImages] = React.useState<any>([]);
  const [loading] = React.useState(false);
  const [isFormReady, formReady] = React.useState(false);
  const [removeImages, setRemoveImages] = React.useState<any>([]);
  const [fields, setFields] = React.useState<any>({
    category: [],
  });

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 100);
  };
  const normFile = (e: any) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleFinish = () => {
    onFinish(removeImages);
  };
  const handleChangeHeaderLogo: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setHeaderLogoUrl(url);
      });
    }
  };
  const handleChangeLogo: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      //setLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        // setLoading(false);
        setLogoUrl(url);
      });
    }
  };

  const handleChangeImages: UploadProps['onChange'] = ({ fileList: newImageList }) => {
    for (let i = 0; i < newImageList.length; i++) {
      newImageList[i].status = 'done';
    }
    setImages(images.concat(newImageList));
  };

  const handleRemoveImages: UploadProps['onRemove'] = (image) => {
    if (image.uid.startsWith('image')) {
      setRemoveImages([...removeImages, image.uid.replace('image', '')]);
    }
  };
  const renderBrand = () => {
    if (dataSource.id > 0) {
      return (
        <Form.Item name="brand" label="브랜드명">
          <Input defaultValue={dataSource.brand} readOnly />
        </Form.Item>
      );
    }

    return (
      <Form.Item name="brandName" label="브랜드명" rules={[{ required: true, message: '브랜드를 선택해주세요' }]}>
        <Input onClick={onRequestBrandSelect} readOnly />
      </Form.Item>
    );
  };
  useEffect(() => {
    const bootstrap = () => {
      if (dataSource.id > 0) {
        let newImages = [];
        if (dataSource.images.length > 0) {
          for (let i = 0; i < dataSource.images.length; i++) {
            const image = dataSource.images[i];
            newImages.push({
              uid: `image${image.id}`,
              name: `image${image.id}`,
              url: image.url,
              status: 'done',
            });
          }
          setImages(newImages);
        }
        form.setFieldsValue({
          id: dataSource.id,
          brandId: dataSource.brandId,
          brandName: dataSource.brandName,
          name: dataSource.name,
          categoryId: dataSource.categoryId,
          dates: [moment(dataSource.startDate), moment(dataSource.endDate)],
          address: dataSource.address,
          latitude: dataSource.latitude,
          longitude: dataSource.longitude,
          detailUrl: dataSource.detailUrl,
          shortIntroduce: dataSource.shortIntroduce,
          images: newImages,
        });
      }

      if (dataSource.id > 0) {
        setHeaderLogoUrl(dataSource.headerLogo);
        setLogoUrl(dataSource.mainImage);
      } else {
        setLogoUrl('');
        setHeaderLogoUrl('');
        setImages([]);
      }

      setRemoveImages([]);
    };
    if (isFormReady) {
      bootstrap();
    }
  }, [isFormReady, dataSource]);
  useEffect(() => {
    const bootstrap = async () => {
      try {
        formReady(false);
        const category = await readAllBrandCategory();
        setFields({ category });
      } catch (e) {
        console.log(e);
      } finally {
        formReady(true);
      }
    };

    bootstrap();
  }, []);
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={{
        brandId: 0,
        brandName: '',
        title: '',
        categoryId: '',
        dates: [null, null],
        address: '',
        latitude: '',
        longitude: '',
        detailUrl: '',
        shortIntroduce: '',
      }}
      onFinish={handleFinish}
    >
      {renderBrand()}
      <Form.Item name="brandId" hidden={true}>
        <Input />
      </Form.Item>
      <Form.Item name="name" label="스토어명" rules={[{ required: true, message: '스토어명을 입력해주세요.' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="dates" label="노출기간" rules={[{ required: true, message: '노출기간을 입력해주세요.' }]}>
        <DatePicker.RangePicker locale={locale} showTime />
      </Form.Item>
      <Form.Item name="categoryId" label="카테고리" rules={[{ required: true, message: '카테고리를 선택해주세요.' }]}>
        <Select>
          {fields.category.map((item: any) => {
            return (
              <Option key={item.id.toString()} value={item.id}>
                {item.name}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item name="address" label="주소" rules={[{ required: true, message: '주소를 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="latitude" label="위도" rules={[{ required: true, message: '위도를 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="longitude" label="경도" rules={[{ required: true, message: '경도를 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="detailUrl" label="상세URL" rules={[{ required: true, message: '상세URL를 입력해주세요' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="shortIntroduce" label="짧은소개" rules={[{ required: true, message: '짧은소개를 입력해주세요' }]}>
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="headerLogo"
        label="헤더로고"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '헤더로고를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="headerLogo"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeHeaderLogo}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {headerLogoUrl ? <img src={headerLogoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="mainImage"
        label="로고"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '대표이미지를 등록해주세요',
          },
        ]}
      >
        <Upload
          name="mainImage"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={handleChangeLogo}
          maxCount={1}
          customRequest={dummyRequest}
          onPreview={function handlePreview(file: UploadFile) {}}
        >
          {logoUrl ? <img src={logoUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
        </Upload>
      </Form.Item>
      <Form.Item
        name="images"
        label="이미지"
        valuePropName="fileList"
        getValueFromEvent={normFile}
        rules={[
          {
            required: dataSource.id < 1 ? true : false,
            message: '이미지를 등록해주세요',
          },
          {
            required: true,
            min: 1,
            max: 3,
            message: '총 3장의 이미지를 등록해주세요',
            type: 'array',
          },
        ]}
      >
        <Upload name="images" listType="picture-card" fileList={images} beforeUpload={beforeUpload} maxCount={5} multiple onChange={handleChangeImages} onRemove={handleRemoveImages} customRequest={dummyRequest}>
          {images.length >= 3 ? null : uploadButton}
        </Upload>
      </Form.Item>
    </Form>
  );
}
