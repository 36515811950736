import * as React from 'react';
import styled from 'styled-components';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Form, Input, Checkbox, Button, message } from 'antd';

import bg from '../../assets/images/login_background.jpeg';
import logo from '../../assets/images/white_logo.png';
import { useNavigate } from 'react-router';
import { authLogin } from '../../api';
import { useDispatch } from 'react-redux';
import { setAuthLogin } from '../../store/auth/slice';
export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSubmit = async (data: any) => {
    try {
      const res: any = await authLogin(data);
      if (res.access_token) {
        localStorage.setItem('token', res.access_token);
        dispatch(setAuthLogin({ isLogin: true, token: res.access_token }));
        navigate('/', { replace: true });
      }
    } catch (e: any) {
      message.error(e.message);
    }
  };

  return (
    <Contaienr>
      <LoginWrap>
        <Title>{/* <Logo src={logo} alt="언니네병원 로고" title="언니네병원" /> */}</Title>
        <Form onFinish={handleSubmit}>
          <Form.Item name="account" rules={[{ required: true, message: '아이디를 입력하세요.' }]}>
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="아이디" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: '비밀번호를 입력하세요' }]}>
            <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="비밀번호" />
          </Form.Item>
          <Form.Item>
            <SubmitButton type="primary" htmlType="submit">
              로그인
            </SubmitButton>
          </Form.Item>
        </Form>
      </LoginWrap>
    </Contaienr>
  );
}

const Contaienr = styled.section`
  height: 100vh;
  background-image: url(${bg});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginWrap = styled.div`
  padding: 50px;
  width: 400px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

const Title = styled.h1`
  margin-bottom: 50px;
`;

const SubmitButton = styled(Button)`
  height: 50px;
  width: 100%;
  border-radius: 4px;
`;

const Logo = styled.img`
  width: 200px;
`;
