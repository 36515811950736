import api from '../services/api';

export type BrandCreatePropTypes = {
  name: string;
  shortIntroduce: string;
  address: string;
  detailUrl: string;
  image: File;
  categoryId: number;
  latitude: string;
  longitude: string;
  logo: File;
};

const headers = { 'Content-Type': 'multipart/form-data' };

export const authLogin = (data: any) => api.post('/auth/signInAdmin', data);

export const readAllMember = (params: any) => api.get('/admin/member', { params: { take: 20, ...params } });
export const readAllBrand = (params: any) => api.get('/admin/brand', { params: { take: 20, ...params } });

export const readAllBrandCategory = () => api.get('/category');
export const readAllStore = (params: any) => api.get('/admin/popup', { params });
export const readAllStoreByBrand = (brandId: number) => api.get(`/admin/brand/popup/${brandId}`);
export const readAllBookmarkByMember = (memberId: number) => api.get(`/admin/member/bookmark/${memberId}`);
export const readAllFollowkByMember = (memberId: number) => api.get(`/admin/member/follow/${memberId}`);
export const readStoreByPK = (id: number) => api.get(`/admin/popup/${id}`);
export const readBrandByPK = (id: number) => api.get('/admin/brand/' + id);

export const createStore = (data: any) => api.post('/admin/popup', data, { headers });
export const createBrand = (data: any) => api.post('/admin/brand', data, { headers });

export const createStoreHomeBannerByPK = (id: number) => api.post('/admin/popup/homeBanner', { popupId: id });
export const createStoreHomeListByPK = (id: number) => api.post('/admin/popup/homeList', { popupId: id });

export const updateStore = (data: any) => api.put(`/admin/popup`, data, { headers });
export const updateBrand = (data: any, id: number) => api.put('/admin/brand/' + id, data, { headers });
export const updateBrandActiveByPK = (value: string, id: number) => api.patch(`/admin/brand/${id}/active/${value}`);

export const updateStoreActiveByPK = (value: string, id: number) => api.patch(`/admin/popup/${id}/active/${value}`);

export const deleteStoreByPK = (id: number) => api.delete(`/admin/popup/${id}`);
export const deleteStoreHomeBannerByPK = (id: number) => api.delete('/admin/popup/homeBanner?popupId=' + id);
export const deleteStoreHomeListByPK = (id: number) => api.delete('/admin/popup/homeList?popupId=' + id);

export const deleteBrand = (id: number) => api.delete('/admin/brand/' + id);
