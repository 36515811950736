import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../configure';
import * as authAPI from './authAPI';
interface AuthState {
  isLogin: boolean;
  authLoading: boolean;
  token: string;
}

const initialState: AuthState = {
  isLogin: false,
  authLoading: false,
  token: '',
};

export const authLogin = createAsyncThunk('auth/login', async (data: any) => {
  const response: any = await authAPI.login(data);
  if (response.access_token) {
    sessionStorage.setItem('token', response.access_token);
  }
  return response;
});
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthLoading(state: AuthState, action: PayloadAction<boolean>) {
      state.authLoading = action.payload;
    },
    setAuthLogin(state: AuthState, action: PayloadAction<any>) {
      state.authLoading = false;
      state.isLogin = action.payload.isLogin;
      state.token = action.payload.token;
    },
  },
  extraReducers(builder) {
    builder.addCase(authLogin.pending, (state) => {
      state.authLoading = true;
      state.isLogin = false;
      state.token = '';
    });
    builder.addCase(authLogin.fulfilled, (state, action) => {
      state.authLoading = false;
      state.isLogin = true;
      state.token = action.payload.access_token;
    });
  },
});
export const { setAuthLoading, setAuthLogin } = authSlice.actions;
export default authSlice.reducer;
