import './App.css';
import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import AppLayout from './components/layouts/AppLayout';
import { useSelector } from 'react-redux';
import { RootState } from './store/configure';

import HomePage from './pages/Home';
import BrandPage from './pages/Brands';
import StorePage from './pages/Stores';
import MemberPage from './pages/Members';
import LoginPage from './pages/Login';
import SettingPage from './pages/Settings';
import NotFoundPage from './pages/NotFound';
import FobiddenPage from './pages/Fobidden';

function AuthRoute() {
  const isLogin = useSelector<RootState>((state) => state.auth.isLogin);
  return isLogin ? <Outlet /> : <Navigate to="/auth/login" />;
}
export default function App() {
  const authLoading = useSelector<RootState>((state) => state.auth.authLoading);
  if (authLoading) {
    return null;
  }
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="/" element={<AppLayout />}>
            <Route index element={<MemberPage />} />
            <Route path="members" element={<MemberPage />} />
            <Route path="brands" element={<BrandPage />} />
            <Route path="stores" element={<StorePage />} />
            <Route path="settings" element={<SettingPage />} />
            <Route path="*" element={<FobiddenPage />} />
          </Route>
        </Route>
        <Route path="auth/login" element={<LoginPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
