import * as React from 'react';
import { Drawer, Modal, PageHeader, Switch, Table, Tag } from 'antd';
import moment from 'moment';
import { readAllFollowkByMember, updateBrandActiveByPK } from '../../../../api';

export default function DrawerFollowListByMember(props: any) {
  const { visible, data, onRequestClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };
  const handleChangeActive = async (item: any) => {
    try {
      await updateBrandActiveByPK(item.is_active_yn === 'Y' ? 'N' : 'Y', item.id);
      bootstrap();
    } catch (e) {
      console.log(e);
    }
  };
  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllFollowkByMember(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader ghost={false} onBack={handleBack} title={`브랜드 목록`}>
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '등록일자',
                dataIndex: 'createdAt',
                render(value) {
                  return moment(value).format('YYYY-MM-DD HH:mm');
                },
              },
              {
                title: '활성/비활성',
                dataIndex: 'is_active_yn',
                render(value: any, item: any) {
                  return <Switch size="small" checked={value === 'Y'} onChange={() => handleChangeActive(item)} />;
                },
              },
              {
                title: '브랜드명',
                dataIndex: 'name',
              },
              {
                title: '주소',
                dataIndex: 'address',
              },
              {
                title: '상세제목',
                dataIndex: 'detailTitle',
              },
              {
                title: '상세 내용',
                dataIndex: 'detailContent',
                render(value, item: any) {
                  return (
                    <a
                      onClick={() => {
                        Modal.info({
                          title: `${item.detailTitle}`,
                          content: value,
                        });
                      }}
                    >
                      보기
                    </a>
                  );
                },
              },
              {
                title: '헤더로고',
                dataIndex: 'headerLogo',
                render(value) {
                  return <a>보기</a>;
                },
              },
              {
                title: '메인이미지',
                dataIndex: 'mainImage',
                render(value) {
                  return <a>보기</a>;
                },
              },
            ]}
          />
        </PageHeader>
      </Drawer>
    </>
  );
}
