import * as React from 'react';
import { Dropdown, Menu, Modal, Space, Switch, Table, Tooltip, message } from 'antd';
import { getTableColumnNo } from '../../../helpers/table';
import moment from 'moment';
import { createStoreHomeBannerByPK, createStoreHomeListByPK, deleteStoreByPK, deleteStoreHomeBannerByPK, deleteStoreHomeListByPK, updateBrandActiveByPK } from '../../../api';
import DrawerStoreListByBrand from './DrawerStoreListByBrand';

export default function ListTable(props: any) {
  const { loading, dataSource, pagination, onRequestRefresh, onRequestUpdate } = props;
  const [storeDrawer, setStoreDrawer] = React.useState<any>({
    visible: false,
    data: null,
  });
  const handleClickRequestUpdate = (item: any) => {
    if (item.is_active_yn === 'Y') {
      message.warning('비활성화 상태에서만 수정 가능하십니다.');
      return;
    }

    onRequestUpdate(item);
  };

  const handleClickRequestDelete = async (item: any) => {
    if (item.is_active_yn === 'Y') {
      message.warning('비활성화 상태에서만 삭제 가능하십니다.');
      return;
    }

    Modal.confirm({
      title: '스토어 삭제 요청',
      content: '스토어정보를 삭제 하시겠습니까?',
      async onOk() {
        try {
          await deleteStoreByPK(item.id);
          message.success('스토어정보가 삭제되었습니다.');
          onRequestRefresh();
        } catch (e: any) {
          console.log(e);
          Modal.error({ title: '스토어정보삭제오류', content: e.message });
        }
      },
    });
  };
  const handleChangeActive = async (item: any) => {
    try {
      await updateBrandActiveByPK(item.is_active_yn === 'Y' ? 'N' : 'Y', item.id);
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeActiveBanner = async (item: any) => {
    try {
      if (item.isHomeBanner === 'Y') {
        await deleteStoreHomeBannerByPK(item.id);
      } else {
        await createStoreHomeBannerByPK(item.id);
      }
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeActiveList = async (item: any) => {
    try {
      if (item.isHomeList === 'Y') {
        await deleteStoreHomeListByPK(item.id);
      } else {
        await createStoreHomeListByPK(item.id);
      }
      onRequestRefresh();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      <Table
        size="small"
        bordered={true}
        scroll={{ x: true }}
        loading={loading}
        dataSource={dataSource}
        pagination={pagination}
        rowKey="id"
        columns={[
          getTableColumnNo(pagination.total, pagination.pageSize, pagination.current),
          {
            title: '스토어ID',
            dataIndex: 'id',
            render(value: number, record: any) {
              return (
                <Tooltip placement="topLeft" title="삭제 또는 수정하실 수 있는 메뉴가 나오십니다.">
                  <Dropdown
                    overlay={
                      <Menu>
                        <Menu.Item onClick={() => handleClickRequestUpdate(record)}>수정</Menu.Item>
                        <Menu.Item onClick={() => handleClickRequestDelete(record)}>삭제</Menu.Item>
                      </Menu>
                    }
                    trigger={['click']}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <Space>{value}</Space>
                    </a>
                  </Dropdown>
                </Tooltip>
              );
            },
          },
          {
            title: '등록일자',
            dataIndex: 'createdAt',
            render(value) {
              return moment(value).format('YYYY-MM-DD HH:mm');
            },
          },
          {
            title: '활성/비활성',
            dataIndex: 'is_active_yn',
            render(value: any, item: any) {
              return <Switch size="small" checked={value === 'Y'} onChange={() => handleChangeActive(item)} />;
            },
          },
          {
            title: '브랜드명',
            dataIndex: 'name',
          },
          {
            title: '주소',
            dataIndex: 'address',
          },
          {
            title: '상세제목',
            dataIndex: 'detailTitle',
          },
          {
            title: '상세 내용',
            dataIndex: 'detailContent',
            render(value, item: any) {
              return (
                <a
                  onClick={() => {
                    Modal.info({
                      title: `${item.detailTitle}`,
                      content: value,
                    });
                  }}
                >
                  보기
                </a>
              );
            },
          },
          {
            title: '스토어수',
            dataIndex: 'popupCount',
            render(value, record: any) {
              return <a onClick={() => setStoreDrawer({ visible: true, data: record.id })}>{value}</a>;
            },
          },
        ]}
      />
      <DrawerStoreListByBrand {...storeDrawer} onRequestClose={() => setStoreDrawer({ visible: false, data: null })} />
    </>
  );
}
