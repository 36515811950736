import * as React from 'react';
import { Button, Result } from 'antd';
import { Navigate, useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
  const navigate = useNavigate();
  return (
    <section style={{ flex: 1 }}>
      <Result
        status="404"
        title="404"
        subTitle="존재하지 않는 페이지입니다."
        extra={
          <Button type="primary" onClick={() => navigate(-1)}>
            돌아가기
          </Button>
        }
      />
    </section>
  );
}
