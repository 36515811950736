import * as React from 'react';
import { Col, Row, Table } from 'antd';
import ConfigBrandCategoryManage from '../../features/ConfigCategoryManage';

export default function Settings() {
  return (
    <Row gutter={24}>
      <Col span={12}>
        <ConfigBrandCategoryManage />
      </Col>
      <Col span={12}></Col>
    </Row>
  );
}
