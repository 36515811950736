import { fork, put } from 'redux-saga/effects';
import { setAuthLogin } from './slice';

function* checkToken() {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (!token || token === undefined || token === null || token === '') {
      throw new Error('token empty');
    }
    yield put(setAuthLogin({ isLogin: true, token: token }));
  } catch (e) {
    yield put(setAuthLogin({ isLogin: false, token: '' }));
  }
}

export default function* () {
  yield fork(checkToken);
  console.log('test');
}
