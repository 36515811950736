//CORE
import React, { useState } from 'react';

import { Input, Modal, Table, message } from 'antd';

//API
import { readAllBrand } from '../../../../api';

const { Search } = Input;

export default function BrandSelectModal({ visible, onSelect, onClose }: any) {
  const [response, setResponse] = useState<any>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const handleOk = () => {
    if (selectedRowKeys.length < 1) {
      message.error('병원을 선택해주세요');
      return;
    }

    onSelect(response.result[response.result.findIndex((v: any) => v.id === selectedRowKeys[0])]);
  };
  const handleSearch = async (keyword: string, nextPage: number) => {
    if (keyword.trim().length >= 1) {
      try {
        const newResponse = await readAllBrand({
          name: keyword,
          take: 20,
          page: nextPage,
        });

        setResponse({ ...newResponse, keyword: keyword, page: nextPage });
      } catch (e) {
        console.log('SEARCH ERROR', e);
      }
    }
  };

  return (
    <Modal visible={visible} onCancel={onClose} title="브랜드검색" width={686} onOk={handleOk} okText="선택완료" cancelText="닫기">
      <Search placeholder="브랜드명" onSearch={(value) => handleSearch(value, 1)} enterButton />
      <Table
        onRow={(record) => {
          return {
            onClick: () => setSelectedRowKeys([record.id]),
          };
        }}
        rowSelection={{
          selections: true,
          type: 'radio',
          selectedRowKeys: selectedRowKeys,
          onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedRowKeys(selectedRowKeys);
          },
        }}
        pagination={{
          position: ['bottomCenter'],
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: (page) => handleSearch(response.keyword, page),
        }}
        columns={[
          {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: '주소',
            dataIndex: 'address',
            key: 'address',
          },
        ]}
        dataSource={response.result}
        rowKey={'id'}
      />
    </Modal>
  );
}
