import React, { useEffect } from 'react';
import { Button, Layout, Menu } from 'antd';
import { AppstoreOutlined, BarChartOutlined, CloudOutlined, ShopOutlined, TeamOutlined, UserOutlined, UploadOutlined, VideoCameraOutlined, MenuUnfoldOutlined, MenuFoldOutlined, PoweroffOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import { MenuClickEventHandler, MenuInfo } from 'rc-menu/lib/interface';
import AppSiderMenu from './AppSiderMenu';

const { Header, Content, Footer, Sider } = Layout;

const items: MenuProps['items'] = [UserOutlined, VideoCameraOutlined, UploadOutlined, BarChartOutlined, CloudOutlined, AppstoreOutlined, TeamOutlined, ShopOutlined].map((icon, index) => ({
  key: String(index + 1),
  icon: React.createElement(icon),
  label: `nav ${index + 1}`,
}));
const menuItems = [
  {
    label: '데시보드',
    key: 'menu-1',
    icon: UserOutlined,
  },
];
const AppLayout: React.FC = (): JSX.Element => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = React.useState<boolean>(false);
  const [selectedKey, setSelectedKey] = React.useState<number>(0);
  const handleMenuClick = ({ key }: MenuInfo): void => {
    console.log(key);
    navigate('members');
  };

  useEffect(() => {}, []);
  return (
    <Layout>
      <Sider trigger={null} theme="light" collapsible collapsed={collapsed}>
        <Header className="sider-header">ADMIN</Header>
        <div style={{ overflow: 'auto' }}>
          <AppSiderMenu />
        </div>
      </Sider>
      <Layout className="site-layout">
        <Header
          className="site-header"
          style={{
            width: '100%',
            backgroundColor: '#FFF',
          }}
        >
          <Button type="link" onClick={() => {
            localStorage.removeItem('token');
            navigate('/auth/login' , {replace : true});
          }}>
            <PoweroffOutlined />
          </Button>
        </Header>
        <Content
          style={{
            margin: '24px 16px 24px',
            overflow: 'auto',
            position: 'relative',
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
