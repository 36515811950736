import { Button, Form, Input, Space, Table } from 'antd';
import * as React from 'react';
import { readAllBrandCategory } from '../../api';

export default function ConfigBrandCategoryManage() {
  const [form] = Form.useForm();
  const [items, setItems] = React.useState<any>([]);
  const handleLoad = async () => {
    try {
      const res = await readAllBrandCategory();
      setItems(res);
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    handleLoad();
  }, []);
  return (
    <>
      <Table
        size="small"
        title={() => {
          return '카테고리설정';
        }}
        pagination={false}
        rowKey={(record) => record.id}
        dataSource={items}
        columns={[
          {
            title: 'No.',
            render(_, __, index) {
              return index + 1;
            },
          },
          {
            title: '명칭',
            dataIndex: 'name',
          },
        ]}
        summary={() => (
          <Table.Summary fixed={'bottom'}>
            <Table.Summary.Row>
              <Table.Summary.Cell index={0}>{items.length + 1}</Table.Summary.Cell>
              <Table.Summary.Cell index={1}>
                <Form form={form} initialValues={{ name: '' }}>
                  <Form.Item name="name">
                    <Input.Group compact>
                      <Input style={{ width: 'calc(100% - 200px)' }} defaultValue="https://ant.design" />
                      <Button type="primary">Submit</Button>
                    </Input.Group>
                  </Form.Item>
                </Form>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          </Table.Summary>
        )}
      />
    </>
  );
}
