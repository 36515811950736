import * as React from 'react';
import { Form, message } from 'antd';
import { Page } from '../../components/ui/Page';
import ListTable from './ListTable';
import ListForm from './ListForm';
import { readAllMember } from '../../api';

export default function Members() {
  const [form] = Form.useForm();
  const [formModal, setFormModal] = React.useState<any>({ visible: false, data: null });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [response, setResponse] = React.useState<any>({
    page: 1,
    total: 0,
    result: [],
  });

  const onSearch = async (nextPage: number) => {
    try {
      setLoading(true);
      const nextFormValues = form.getFieldsValue();
      delete nextFormValues.dates;
      const nextResponse: any = await readAllMember({
        ...nextFormValues,
        take: 20,
        page: nextPage,
      });
      setResponse({
        page: nextPage,
        total: nextResponse.total,
        result: nextPage > 1 ? response.result.concat(nextResponse.result) : nextResponse.result,
      });
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    onSearch(1);
  }, []);

  return (
    <Page title="회원관리" subTitle="회원 리스트">
      <ListForm form={form} searchLoading={loading} onRequestSearch={onSearch} />
      <ListTable
        loading={loading}
        dataSource={response.result}
        pagination={{
          position: ['bottomCenter'],
          current: response.page,
          total: response.total,
          pageSize: 20,
          hideOnSinglePage: true,
          showSizeChanger: false,
          onChange: onSearch,
        }}
      />
    </Page>
  );
}
