import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  baseURL: 'https://api.fulneeds.com',
  headers: {
    Authorization: 'bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiQURNSU4iLCJpYXQiOjE2NTQ1NzIwMjAsImV4cCI6MTY4NjEyOTYyMH0.V9XwyBBV27gC7RSWCerRL0lhQ-x-iYm5SrHE4LD-aLw',
  },
});

instance.interceptors.response.use(function (data: AxiosResponse<any, any>) {
  return data.data;
});

export default instance;
