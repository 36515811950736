import React from 'react';
import { Form, Modal, message } from 'antd';
import StoreForm from './StoreForm';
import { createStore, readStoreByPK, updateStore } from '../../../api';
import BrandSelectModal from './BrandSelectModal';

export type FormType = {
  id?: number;
  brandId: number;
  startDate: string;
  endDate: string;
  categoryId: string;
  address: string;
  latitude: string;
  longitude: string;
};
interface IProps {
  visible: boolean;
  data: any;
  onFinish: () => void;
  onRequestClose: () => void;
}

const initialValues = {
  id: 0,
  brandId: 0,
  startDate: '',
  endDate: '',
  categoryId: '',
  address: '',
  latitude: '',
  longitude: '',
} as FormType;
export default function FormModal(props: IProps) {
  const { visible, data, onRequestClose, onFinish } = props;
  const isUpdated = data ? true : false;
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [hospitalModal, setHospitalModal] = React.useState<boolean>(false);
  const [dataSource, setDataSource] = React.useState<FormType>(initialValues);
  const [form] = Form.useForm();

  const resetFormValue = () => {
    setDataSource(initialValues);
    form.resetFields();
  };
  const handleLoad = async (data: any) => {
    try {
      setConfirmLoading(true);
      const res: any = await readStoreByPK(data);
      setDataSource(res);
    } catch (e) {
      console.log(e);
    } finally {
      setConfirmLoading(false);
    }
  };
  const handleFinish = async (removeImages: any) => {
    const values = await form.validateFields();
    try {
      const formData = new FormData();
      if (data > 0) {
        formData.append('id', data);
      }
      formData.append('name', values.name);
      formData.append('shortIntroduce', values.shortIntroduce);
      formData.append('address', values.address);
      formData.append('latitude', values.latitude);
      formData.append('longitude', values.longitude);
      formData.append('detailUrl', values.detailUrl);
      formData.append('brandId', values.brandId);
      formData.append('categoryId', values.categoryId);
      if (values.mainImage && values.mainImage[0]) {
        formData.append('mainImage', values.mainImage[0].originFileObj);
      }
      if (removeImages.length > 0) {
        for (let i = 0; i < removeImages.length; i++) {
          formData.append('deleteImage', removeImages[i]);
        }
      }
      formData.append('startDate', values.dates[0].format('YYYY-MM-DD HH:mm:ss'));
      formData.append('endDate', values.dates[1].format('YYYY-MM-DD HH:mm:ss'));
      if (values.headerLogo && values.headerLogo[0]) {
        formData.append('headerLogo', values.headerLogo[0].originFileObj);
      }
      if (values.images && values.images.length > 0) {
        for (let i = 0; i < values.images.length; i++) {
          if (values.images[i].originFileObj) {
            formData.append('images', values.images[i].originFileObj);
          }
        }
      }

      if (data) {
        await updateStore(formData);
      } else {
        await createStore(formData);
      }
      setConfirmLoading(false);
      message.info(`${isUpdated ? '수정' : '등록'} 되었습니다.`);
      onRequestClose();
      onFinish();
    } catch (e: any) {
      console.log(e);
      Modal.error({
        title: '검증오류',
        content: e.message,
        onOk() {
          // onFinish();
        },
      });
      console.log(e);
    }
  };
  const handleOk = () => {
    form.submit();
  };

  const handleCancel = () => {
    onRequestClose();
    resetFormValue();
  };

  React.useEffect(() => {
    if (visible) {
      form.resetFields();
      if (data) {
        handleLoad(data);
      } else {
        resetFormValue();
        setHospitalModal(true);
      }
    }
  }, [visible, data]);

  return (
    <>
      <BrandSelectModal
        visible={hospitalModal}
        onSelect={(record: any) => {
          form.setFieldsValue({
            ...form.getFieldsValue(),
            brandName: record.name,
            brandId: record.id,
          });
          setHospitalModal(false);
        }}
        onClose={() => setHospitalModal(false)}
      />
      <Modal visible={visible} confirmLoading={confirmLoading} title={`스토어 정보 ${isUpdated ? '수정' : '등록'}`} okText={isUpdated ? '수정' : '등록'} cancelText="취소" onOk={handleOk} onCancel={handleCancel} width={686}>
        {visible && <StoreForm form={form} dataSource={dataSource} onFinish={handleFinish} onRequestBrandSelect={() => setHospitalModal(true)} />}
      </Modal>
    </>
  );
}
