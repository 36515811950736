import * as React from 'react';
import { Space, Button, Form, Select, Input, DatePicker } from 'antd';
import { readAllBrandCategory } from '../../../api';

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
  onRequestCreate: () => void;
}
export default function ListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch, onRequestCreate } = props;
  const [fields, setFields] = React.useState<any>({
    category: [],
  });
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  React.useEffect(() => {
    const bootstrap = async () => {
      try {
        const category = await readAllBrandCategory();
        setFields({
          category,
        });
      } catch (e) {
        console.log(e);
      }
    };
    bootstrap();
  }, []);

  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          useAudition: null,
          departmentId: null,
          occupationId: null,
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <DatePicker.RangePicker allowClear />
        </Form.Item>
        <Form.Item name="brandName">
          <Input placeholder="브랜드명" allowClear />
        </Form.Item>
        <Form.Item name="popupName">
          <Input placeholder="스토어명" allowClear />
        </Form.Item>
        <Form.Item name="category">
          <Select placeholder="카테고리" allowClear>
            {fields.category.map((item: any) => {
              return (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="active">
          <Select placeholder="활성/비활성화" allowClear>
            <Select.Option value={'Y'}>활성화</Select.Option>
            <Select.Option value={'N'}>비활성화</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="isHomeBanner">
          <Select placeholder="배너표시" allowClear>
            <Select.Option value={'Y'}>표시</Select.Option>
            <Select.Option value={'N'}>미표시</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="isHomeList">
          <Select placeholder="목록표시" allowClear>
            <Select.Option value={'Y'}>표시</Select.Option>
            <Select.Option value={'N'}>미표시</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={onRequestCreate}>
            등록
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
