import * as React from "react";
import { Space, Button, Form, Select, Input, DatePicker } from "antd";

interface IProps {
  form: any;
  searchLoading: boolean;
  onRequestSearch: (page: number) => void;
}
export default function ListForm(props: IProps) {
  const { form, searchLoading, onRequestSearch } = props;
  const handleFinish = () => {
    if (onRequestSearch) {
      onRequestSearch(1);
    }
  };
  return (
    <Space size={1}>
      <Form
        form={form}
        layout="inline"
        initialValues={{
          useAudition: null,
          departmentId: null,
          occupationId: null,
        }}
        onFinish={handleFinish}
      >
        <Form.Item name="dates">
          <DatePicker.RangePicker placeholder={['가입시작일' , '가입종료일']}/>
        </Form.Item>
        <Form.Item name="name">
          <Input placeholder="이름" />
        </Form.Item>
        <Form.Item name="nickname">
          <Input placeholder="닉네임" />
        </Form.Item>
        <Form.Item name="email">
          <Input placeholder="이메일" />
        </Form.Item>
        <Form.Item name="isTermMarketing">
          <Select placeholder="마케팅동의">
            <Select.Option value={"Y"}>동의</Select.Option>
            <Select.Option value={"N"}>미동의</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item name="isAppPush">
          <Select placeholder="앱푸쉬동의">
            <Select.Option value={"Y"}>동의</Select.Option>
            <Select.Option value={"N"}>미동의</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" loading={searchLoading} htmlType="submit">
            검색
          </Button>
        </Form.Item>
      </Form>
    </Space>
  );
}
