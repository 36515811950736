import * as React from 'react';
import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function FobiddenPage() {
  const navigate = useNavigate();
  return (
    <section style={{ flex: 1 }}>
      <Result
        status="404"
        title="404"
        subTitle="로그인이 필요한 페이지 입니다."
        extra={
          <Button type="primary" onClick={() => navigate('/auth/login')}>
            로그인하기
          </Button>
        }
      />
    </section>
  );
}
