import * as React from 'react';
import { Drawer, Modal, PageHeader, Switch, Table, Tag } from 'antd';
import moment from 'moment';
import { createStoreHomeBannerByPK, createStoreHomeListByPK, deleteStoreHomeBannerByPK, deleteStoreHomeListByPK, readAllBookmarkByMember, readAllStoreByBrand, updateStoreActiveByPK } from '../../../../api';

export default function DrawerBookmarkListByMember(props: any) {
  const { visible, data, onRequestClose } = props;
  const [loading, setLoading] = React.useState(false);
  const [items, setItems] = React.useState<any>([]);
  const handleBack = () => {
    if (onRequestClose) {
      onRequestClose();
    }
  };
  const handleChangeActive = async (item: any) => {
    try {
      await updateStoreActiveByPK(item.is_active_yn === 'Y' ? 'N' : 'Y', item.id);
      bootstrap();
    } catch (e) {
      console.log(e);
    }
  };
  const handleChangeActiveBanner = async (item: any) => {
    try {
      if (item.isHomeBanner === 'Y') {
        await deleteStoreHomeBannerByPK(item.id);
      } else {
        await createStoreHomeBannerByPK(item.id);
      }
      bootstrap();
    } catch (e) {
      console.log(e);
    }
  };

  const handleChangeActiveList = async (item: any) => {
    try {
      if (item.isHomeList === 'Y') {
        await deleteStoreHomeListByPK(item.id);
      } else {
        await createStoreHomeListByPK(item.id);
      }
      bootstrap();
    } catch (e) {
      console.log(e);
    }
  };
  const bootstrap = async () => {
    try {
      setLoading(true);
      const res = await readAllBookmarkByMember(data);
      setItems(res);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  React.useEffect(() => {
    if (visible) {
      bootstrap();
    }
  }, [visible]);

  return (
    <>
      <Drawer visible={visible} getContainer={false} width={'100%'} closable={false} style={{ position: 'absolute' }}>
        <PageHeader ghost={false} onBack={handleBack} title={`북마크 목록`}>
          <Table
            size="small"
            bordered={true}
            dataSource={items}
            loading={loading}
            pagination={false}
            columns={[
              {
                title: '등록일자',
                dataIndex: 'createdAt',
                render(value) {
                  return moment(value).format('YYYY-MM-DD HH:mm');
                },
              },
              {
                title: '활성/비활성',
                dataIndex: 'is_active_yn',
                render(value: any, item: any) {
                  return <Switch size="small" checked={value === 'Y'} onChange={() => handleChangeActive(item)} />;
                },
              },
              {
                title: '노출시작일',
                dataIndex: 'startDate',
                render(value) {
                  return moment(value).format('YYYY-MM-DD HH:mm');
                },
              },
              {
                title: '노출종료일',
                dataIndex: 'endDate',
                render(value) {
                  return moment(value).format('YYYY-MM-DD HH:mm');
                },
              },

              {
                title: '스토어제목',
                dataIndex: 'name',
              },
              {
                title: '카테고리',
                dataIndex: 'category',
              },
              {
                title: '주소',
                dataIndex: 'address',
              },
              {
                title: '위도',
                dataIndex: 'latitude',
              },
              {
                title: '경도',
                dataIndex: 'longitude',
              },
              {
                title: '소개글',
                dataIndex: 'shortIntroduce',
                render(value: any, item: any) {
                  return (
                    <a
                      onClick={() => {
                        Modal.info({
                          title: `${item.name}의 소개글`,
                          content: item.shortIntroduce,
                        });
                      }}
                    >
                      보기
                    </a>
                  );
                },
              },
              {
                title: '헤더로고',
                dataIndex: 'headerLogo',
                render(value) {
                  return <a>보기</a>;
                },
              },
              {
                title: '메인이미지',
                dataIndex: 'mainImage',
                render(value) {
                  return <a>보기</a>;
                },
              },
              {
                title: '이미지',
                dataIndex: 'images',
                render(value) {
                  return <a>보기</a>;
                },
              },
              {
                title: '배너표시',
                dataIndex: 'isHomeBanner',
                render(value: any, item: any) {
                  return <Switch size="small" checked={value === 'Y'} onChange={() => handleChangeActiveBanner(item)} />;
                },
              },
              {
                title: '목록표시',
                dataIndex: 'isHomeList',
                render(value: any, item: any) {
                  return <Switch size="small" checked={value === 'Y'} onChange={() => handleChangeActiveList(item)} />;
                },
              },
            ]}
          />
        </PageHeader>
      </Drawer>
    </>
  );
}
